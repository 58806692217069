/*--------
   COLORS
----------*/
$orange50: #fffbee;
$orange300: #ffbc1f;
$orange475: #ff8533;
$orange500: #fd6400;
$orange510: #fb6500;
$orange525: #e05a00;
$orange700: #e03000;
$orangeLighter: #ff9f8e;
$dangerLight: #feebeb;
$white: #ffffff;
$black: #000000;
$gray900: #202122;
$gray700: #5F6063;
$gray100: #f5f5f5;
$gray300: #e0e0e1;
$blue500: #0058ff;
$blue700: #000faa;
$blue900:#000060;
$loaderBlue: #0000a3;
$darkblue: #000099;
$blueGray: #140199;
$gray: #666666;
$gray100: #f6f6f6;
$gray200: #ededee;
$gray400: #bbbcbe;
$grayDarker: #333333;
$blue50: #f7fcfd;
$blue100: #6b8ce8;
$blue: #58c9ff;
$powderedBlue: #e2ecf0;
$pigeonPost: #a7c6d6;
$blueLighter: #427ac9;
$grayLight: #aaaaaa;
$grayLighter: #cccccc;
$lightShadeGray: #eaeaea;
$grayLightest: #e3e3e3;
$brand02dark: #19197c;
$brand02darkest: #141240;
$green700: #009354;
$green500: #00b269;
$greenLight: #eff9f3;
