/*--------------------
       IMPORTS
--------------------*/

@import "colors.scss";
@import "mixins";

html,
body {
  height: 100%;
  margin: 0;
  word-break: break-word;
}

.button-primary-orange {
  width: 272px;
  height: 48px;
  background-color: $orange510;
  border-radius: 4px;
  border: none;
  @include myriad-pro-text-bold(19px, $white, 700, normal, 21px);
  &:hover {
    background-color: $orange475;
  }
  &:active {
    background-color: $orange525;
  }
  &:focus {
    background-color: $orange510;
    box-shadow: 0px 0px 0px 3px $blue100;
    border-radius: 4px;
    outline: none;
  }
  &:disabled,
  [disabled] {
    background-color: $grayLighter;
    @include myriad-pro-text-regular(19px, $gray, 700, normal, 21px);
    cursor: not-allowed;
  }
}

.button-primary-gray {
  width: 272px;
  height: 48px;
  background-color: $gray400; /* Use your desired shade of gray */
  border-radius: 4px;
  border: none;
  @include myriad-pro-text-bold(19px, $white, 700, normal, 21px);
  &:hover {
    background-color: $gray100; /* Use a slightly lighter shade for hover */
  }
  &:active {
    background-color: $gray300; /* Use a slightly darker shade for active state */
  }
  &:focus {
    background-color: $gray400; /* Revert to the original shade for focus */
    box-shadow: 0px 0px 0px 3px $blue100;
    border-radius: 4px;
    outline: none;
  }
  &:disabled,
  [disabled] {
    background-color: $grayLighter; /* Use a lighter shade for disabled state */
    @include myriad-pro-text-regular(19px, $gray, 700, normal, 21px);
    cursor: not-allowed;
  }
}
.button-primary-white {
  @include myriad-pro-text-bold(19px, $brand02dark, 700, normal, 24px);
  border: 1px solid $brand02dark;
  border-radius: 4px;
  background-color: $white;
  padding: 12px 24px;
  &:hover {
    border-color: $blueLighter;
    color: $blueLighter;
  }
  &:focus {
    box-shadow: 0px 0px 0px 3px $blue100;
    border-radius: 4px;
    outline: none;
  }
  &:disabled,
  [disabled] {
    background-color: $grayLighter;
    @include myriad-pro-text-regular(19px, $gray, 700, normal, 21px);
    cursor: not-allowed;
    border: none;
  }
}

.button-secondary-white {
  width: 272px;
  height: 48px;
  background-color: $white;
  border: 1px solid $brand02dark;
  border-radius: 4px;
  @include myriad-pro-text-bold(19px, $brand02dark, 700, normal, 21px);
  &:hover {
    border: 1px solid $blueLighter;
    @include myriad-pro-text-bold(19px, $blueLighter, 700, normal, 21px);
  }
  &:active {
    background-color: $grayLightest;
  }
  &:focus {
    box-shadow: 0px 0px 0px 3px $blue100;
  }
  &:disabled,
  [disabled] {
    background-color: $grayLighter;
    @include myriad-pro-text-regular(19px, $gray, 700, normal, 21px);
    cursor: not-allowed;
    border: none;
  }
}

button.disabled,
.btn.disabled {
  background-color: $grayLighter;
  @include myriad-pro-text-regular(19px, $gray, 700, normal, 21px);
  cursor: not-allowed;
  border: none;
}

.label {
  @include myriad-pro-text-semibold(16px, $gray900, 600, normal, 18px);
  margin-bottom: 8px;
}
.customInput {
  @include myriad-pro-text-regular(18px, $brand02darkest, 400, normal, 24px);
  width: calc(100% - 5px);
  min-width: calc(100% - 5px);
  height: 48px;
  background-color: $white;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 12px 16px;
  &:hover {
    border: 1px solid $brand02dark;
  }
  &:focus {
    border: 1px solid $brand02dark;
    box-shadow: 0px 0px 0px 3px $blue100;
  }
}
input::placeholder {
  @include myriad-pro-text-regular(18px, $gray, 400, normal, 24px);
}

.errorInputBorder {
  border: 1px solid $orange700 !important;
  box-shadow: none !important;
}

.error {
  @include myriad-pro-text-regular(16px, $orange700, 400, normal, 18px);
}

.accordion-item {
  background-color: $gray100;
  border: none;
  .accordion-header {
    .accordion-button {
      background-color: $gray100;
      align-items: flex-start;
      padding: 0;
      @include myriad-pro-text-regular(16px, $gray900, 400, normal, 24px);
      &:focus {
        box-shadow: none;
      }
    }
    .accordion-button::after {
      background-image: url("../../assets/images/down-arrow.svg");
      background-repeat: no-repeat;
      position: relative;
      top: 8px;
    }

    .accordion-button:not(.collapsed)::after {
      position: relative;
      top: -2px;
    }

    .accordion-button:not(.collapsed) {
      box-shadow: none;
      padding-bottom: 8px;
    }
  }
  .accordion-body {
    border-top: 1px solid $white;
    @include myriad-pro-text-regular(14px, $gray900, 400, normal, 24px);
    padding: 8px 0 0 0;
    white-space: pre-line;
  }
}

/* Custom radio buttons */
input[type="radio"] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  @include myriad-pro-text-regular(16px, $brand02darkest, 400, normal, 21px);

  &:before {
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    margin-right: 14px;
    position: absolute;
    top: -3px;
    left: 0;
    border: 1px solid $grayLighter;
    background-color: $white;
    border-radius: 50%;
  }
}

input[type="radio"] {
  display: none !important;

  &:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    left: 5px;
    top: 2px;
    border-radius: 50%;
    background: $orange500;
  }
}

// Hovering when not disabled
input[type="radio"]:not([disabled]) + label:hover::before {
  border: 1px solid $orange500;
}

input[type="radio"]:not([disabled]) + label:active::before {
  border: 1px solid $orange500;
  box-shadow: 0px 0px 0px 3px $orangeLighter;
}

//disabled border
input[type="radio"][disabled] + ::before {
  border: 1px solid $grayLighter;
}

//disabled text
input[type="radio"][disabled] + label {
  @include myriad-pro-text-regular(16px, $grayLight, 400, normal, 21px);
  color: $grayLight !important;
  cursor: not-allowed;
}

input[type="radio"]:checked[disabled] + label::after {
  background: $grayLighter;
}

// End Custom radio buttons

.button-primary-orange {
  .spinner-border {
    width: 24px;
    height: 24px;
  }
}

.nav-pills {
  .nav-item {
    .nav-link {
      @include myriad-pro-text-regular(
        16px,
        $brand02darkest,
        400,
        normal,
        19px
      );
      // margin-right: 56px;
      padding: 0;
      background: none;
      &.active {
        @include myriad-pro-text-bold(16px, $brand02darkest, 400, normal, 19px);
        border-bottom: 2px solid $orange510;
        padding-bottom: 10px;
        border-radius: 0;
      }
      &.non-active {
        border: none !important;
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

textarea {
  background: $white;
  border: 1px solid $grayLighter;
  border-radius: 4px;
  padding: 12px 16px;
  resize: none;
  @include myriad-pro-text-regular(16px, $brand02darkest, 400, normal, 24px);
  &:hover {
    border-color: $brand02darkest;
  }
  &:focus {
    box-shadow: 0px 0px 0px 3px $blue100;
    outline: none;
  }
  &:disabled,
  [disabled] {
    color: $grayLight;
    cursor: not-allowed;
  }
}

.publish-block {
  padding: 40px 9.2% 36px 9.2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    display: inline-block;
    word-break: break-word;
    @include myriad-pro-text-regular(18px, $brand02darkest, 400, normal, 22px);
  }
}

.company-name {
  @include myriad-pro-text-regular(24px, $blue500, 400, italic, 29px);
  margin-bottom: 40px;
}

.pageHeading {
  display: flex;
  justify-content: space-between;
  align-items: self-end;
}

input[type="checkbox"] {
  accent-color: $orange525;
}
