/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
// global styles
@import "app/styles/base";

html,
body {
  height: 100%;
  margin: 0;
  word-break: break-word;
}

.platform-name {
  @include myriad-pro-text-regular(38px, $blue500, 400, italic, 46px);
  margin-bottom: 56px;
}

.clientLogin {
  @include myriad-pro-text-regular(24px, $blue500, 400, italic, 29px);
}
.login-description {
  @include myriad-pro-text-regular(16px, $gray900, 400, normal, 24px);
  margin: 32px 0 24px 0;
}

.cursor {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.pointer-events-none {
  pointer-events: none;
}

.toast-right-top.success {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px 20px 24px;
  gap: 8px;

  position: absolute;
  width: 790px;
  height: 62px;
  left: 158px;
  top: 80px;

  /* State/success-light */

  background: #eff9f3;
  /* New Palette/Green/G500 */

  border-width: 1px 1px 1px 8px;
  border-style: solid;
  border-color: #00b269;
  border-radius: 4px;
}

.begoal-title {
  @include myriad-pro-text-regular(38px, $blue500, 400, italic, 46px);
}

.grey-box {
  background-color: $gray100;
}

.blue-box {
  background-color: $blueGray;
}

.sidePadding {
  padding: 0 9.2% 0 9.2%;
}

.submitAnswersModal {
  --bs-modal-border-radius: initial;
  .modal-dialog {
    max-width: 40%;
  }
  .submitAnswerConfirmationModal {
    padding: 40px;
    .modal-header {
      border: none;
      &.title {
        @include myriad-pro-text-regular(24px, $blue500, 400, italic, 29px);
      }
    }
    .modal-body {
      border: none;
      @include myriad-pro-text-regular(16px, $gray900, 400, normal, 24px);
    }
    .modal-footer {
      border: none;
    }
  }
}

.ruler {
  border: 1px solid $gray300;
}

.button-cancel {
  @include myriad-pro-text-regular(19px, $brand02dark, 400, normal, 24px);
}

@media only screen and (max-width: 1000px) {
  /* For mobile phones: */
  .submitAnswersModal {
    .modal-dialog {
      max-width: fit-content;
    }
  }
}

b,
strong {
  font-family: "Myriad Pro Bold", sans-serif;
}

button {
  .spinner-border {
    width: 24px;
    height: 24px;
  }
}

.deepdive-notes-title {
  @include myriad-pro-text-regular(18px, $blue700, 400, italic, 22px);
}

.add-buttons-block {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  .cancel-button {
    margin-right: 35px;
    @include myriad-pro-text-regular(19px, $brand02dark, 400, normal, 24px);
  }
}

.loader {
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;

    &__circle {
      position: relative;
      width: 54px;
      height: 54px;
      overflow: hidden;
      background-color: $loaderBlue;
      border-radius: 100%;
      animation: spin 1s infinite linear;

      &-inner,
      &-gradient {
        position: absolute;
        width: 44px;
        height: 44px;
        transform: translate(-50%, -50%);
      }

      &-inner {
        top: 50%;
        left: 50%;
        background-color: $white;
        border-radius: 100%;
      }

      &-gradient {
        top: -24px;
        left: 6px;
        background-color: $loaderBlue;
        background-image: -moz-linear-gradient(
          0deg,
          $loaderBlue 0%,
          $white 50%
        );
        background-image: -webkit-linear-gradient(
          0deg,
          $loaderBlue 0%,
          $white 50%
        );
        background-image: linear-gradient(0deg, $loaderBlue 0%, $white 50%);
        transform: rotate(-45deg);
      }
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
