@font-face {
  font-family: "Myriad Pro Regular";
  src: local("Myriad Pro Regular"),
    url("../../assets/fonts/MYRIADPRO-REGULAR.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed";
  src: local("Myriad Pro Condensed"),
    url("../../assets/fonts/MYRIADPRO-COND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed Italic";
  src: local("Myriad Pro Condensed Italic"),
    url("../../assets/fonts/MYRIADPRO-CONDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Light";
  src: local("Myriad Pro Light"),
    url("../../assets/fonts/MyriadPro-Light.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold";
  src: local("Myriad Pro Semibold"),
    url("../../assets/fonts/MYRIADPRO-SEMIBOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold Italic";
  src: local("Myriad Pro Semibold Italic"),
    url("../../assets/fonts/MYRIADPRO-SEMIBOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed";
  src: local("Myriad Pro Bold Condensed"),
    url("../../assets/fonts/MYRIADPRO-BOLDCOND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold";
  src: local("Myriad Pro Bold"),
    url("../../assets/fonts/MYRIADPRO-BOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Italic";
  src: local("Myriad Pro Bold Italic"),
    url("../../assets/fonts/MYRIADPRO-BOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed Italic";
  src: local("Myriad Pro Bold Condensed Italic"),
    url("../../assets/fonts/MYRIADPRO-BOLDCONDIT.woff") format("woff");
}

@mixin myriad-pro-text-regular(
  $fontSize,
  $color,
  $fontWeight,
  $fontStyle,
  $lineHeight,
) {
  font-family: Myriad Pro Regular, sans-serif;
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
}

@mixin myriad-pro-text-condensed(
  $fontSize,
  $color,
  $fontWeight,
  $fontStyle,
  $lineHeight,
  $letterSpacing
) {
  font-family: Myriad Pro Condensed, sans-serif;
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin myriad-pro-text-condensed-italic(
  $fontSize,
  $color,
  $fontWeight,
  $fontStyle,
  $lineHeight,
  $letterSpacing
) {
  font-family: Myriad Pro Condensed Italic, sans-serif;
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin myriad-pro-text-light(
  $fontSize,
  $color,
  $fontWeight,
  $fontStyle,
  $lineHeight,
  $letterSpacing
) {
  font-family: Myriad Pro Light, sans-serif;
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}
@mixin myriad-pro-text-semibold(
  $fontSize,
  $color,
  $fontWeight,
  $fontStyle,
  $lineHeight
) {
  font-family: Myriad Pro Semibold, sans-serif;
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
}
@mixin myriad-pro-text-semibold-italic(
  $fontSize,
  $color,
  $fontWeight,
  $fontStyle,
  $lineHeight,
  $letterSpacing
) {
  font-family: Myriad Pro Semibold Italic, sans-serif;
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}
@mixin myriad-pro-text-bold-condensed(
  $fontSize,
  $color,
  $fontWeight,
  $fontStyle,
  $lineHeight,
  $letterSpacing
) {
  font-family: Myriad Pro Bold Condensed, sans-serif;
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}
@mixin myriad-pro-text-bold(
  $fontSize,
  $color,
  $fontWeight,
  $fontStyle,
  $lineHeight
) {
  font-family: Myriad Pro Bold, sans-serif;
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
}
@mixin myriad-pro-text-bold-italic(
  $fontSize,
  $color,
  $fontWeight,
  $fontStyle,
  $lineHeight,
  $letterSpacing
) {
  font-family: Myriad Pro Bold Italic, sans-serif;
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}
@mixin myriad-pro-text-bold-condensed-italic(
  $fontSize,
  $color,
  $fontWeight,
  $fontStyle,
  $lineHeight,
  $letterSpacing
) {
  font-family: Myriad Pro Bold Condensed Italic, sans-serif;
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}
//   @mixin lato-text-active-bold($fontSize: 12px, $color: $black, $fontWeight: 600) {
//     font-family: Lato-Regular,sans-serif;
//     font-weight: $fontWeight;
//     font-size: $fontSize;
//     color: $color;
//   }
//   @mixin raleway-text-regular($fontSize, $color, $fontWeight, $fontStyle, $lineHeight, $letterSpacing) {
//     font-family: Raleway-Regular, sans-serif;
//     font-size: $fontSize;
//     color: $color;
//     font-weight: $fontWeight;
//     font-style: $fontStyle;
//     line-height: $lineHeight;
//     letter-spacing: $letterSpacing;
//   }

//   @mixin raleway-text-medium($fontSize: 12px, $color: $black) {
//     font-family: Raleway-Medium,sans-serif;
//     font-weight: 500;
//     font-size: $fontSize;
//     color: $color;
//   }
//   @mixin raleway-text-bold($fontSize, $color, $fontWeight, $fontStyle, $lineHeight, $letterSpacing) {
//     font-family: Raleway-Bold, sans-serif;
//     font-size: $fontSize;
//     color: $color;
//     font-weight: $fontWeight;
//     font-style: $fontStyle;
//     line-height: $lineHeight;
//     letter-spacing: $letterSpacing;
//   }

//   @mixin raleway-text-extra-bold($fontSize: 36px, $color: $black) {
//     font-family: Raleway-ExtraBold,sans-serif;
//     font-weight: 800;
//     font-size: $fontSize;
//     color: $color;
//   }

//   @mixin raleway-text-medium($fontSize: 12px, $color: $black) {
//     font-family: Raleway-Medium,sans-serif;
//     font-weight: 500;
//     font-size: $fontSize;
//     color: $color;
//   }
